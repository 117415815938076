import { useMemo } from 'react'

export const Logo = ({
  variant = 'primary',
  as: Element = 'h1',
  style = {},
  ...props
}) => {

  const logoSyles = useMemo(() => ({
    fontSize: '24px',
    fontWeight: '900',
    fontFamily: '\'Montserrat Alternates\', sans-serif',
    margin: '0',
    letterSpacing: '-2px',
    color: `var(--on-surface-${variant})`,
    ...style
  }), [style, variant])

  return (
    <Element style={logoSyles} {...props}>
      OSSY
    </Element>
  )
}
