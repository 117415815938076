import React, { useMemo } from 'react'
import { css } from 'glamor'

const Default = {
  display: 'flex',
  flexDirection: 'column',
}

const Row = {
  display: 'flex',
  flexDirection: 'row'
}

const RowWrap = {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row'
}

const OffCenter = {
  display: 'grid',
  gridTemplateRows: '1fr max-content 2fr',
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
    "."
    "content"
    "."
  `,

  '& [slot="content"]': { gridArea: 'content' }
}

const OffCenterSmall = {
  height: '100%',
  width: '100%',
  maxWidth: '100%',
  display: 'grid',
  gridTemplateRows: 'auto 1fr minmax(400px, min-content) 2.2fr auto',
  gridTemplateColumns: 'auto 1fr minmax(min(350px, 100vw), min-content) 1fr auto',
  gridTemplateAreas: `
    "header header header header header"
    "sidebar-primary . . . sidebar-secondary"
    "sidebar-primary . content . sidebar-secondary"
    "sidebar-primary . . . sidebar-secondary"
    "footer footer footer footer footer"
  `,

  '& [slot="header"]': { gridArea: 'header' },
  '& [slot="content"]': { gridArea: 'content' },
  '& [slot="sidebar-primary"]': { gridArea: 'sidebar-primary' },
  '& [slot="sidebar-secondary"]': { gridArea: 'sidebar-secondary' },
  '& [slot="footer"]': { gridArea: 'footer' }
}

const OffCenterMedium = {
  height: '100%',
  width: '100%',
  maxWidth: '100%',
  display: 'grid',
  gridTemplateRows: 'auto 1fr minmax(650px, min-content) 2.2fr auto',
  gridTemplateColumns: 'auto 1fr minmax(min(1100px, 100%), min-content) 1fr auto',
  gridTemplateAreas: `
    "header header header header header"
    "sidebar-primary . . . sidebar-secondary"
    "sidebar-primary . content . sidebar-secondary"
    "sidebar-primary . . . sidebar-secondary"
    "footer footer footer footer footer"
  `,

  '& [slot="header"]': { gridArea: 'header' },
  '& [slot="content"]': { gridArea: 'content' },
  '& [slot="sidebar-primary"]': { gridArea: 'sidebar-primary' },
  '& [slot="sidebar-secondary"]': { gridArea: 'sidebar-secondary' },
  '& [slot="footer"]': { gridArea: 'footer' }
}

const VerticalTop = {
  display: 'grid',
  gridTemplateRows: '.05fr max-content 1fr',
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
    "."
    "content"
    "."
  `,

  '& [slot="content"]': { gridArea: 'content' }
}

const VerticalTopMedium = {
  height: '100%',
  width: '100%',
  maxWidth: '100%',
  display: 'grid',
  gridTemplateRows: 'auto 1fr minmax(650px, min-content) 3r auto',
  gridTemplateColumns: 'auto 1fr minmax(1100px, min-content) 1fr auto',
  gridTemplateAreas: `
    "header header header header header"
    "sidebar-primary . . . sidebar-secondary"
    "sidebar-primary . content . sidebar-secondary"
    "sidebar-primary . . . sidebar-secondary"
    "footer footer footer footer footer"
  `,

  '& [slot="header"]': { gridArea: 'header' },
  '& [slot="content"]': { gridArea: 'content' },
  '& [slot="sidebar-primary"]': { gridArea: 'sidebar-primary' },
  '& [slot="sidebar-secondary"]': { gridArea: 'sidebar-secondary' },
  '& [slot="footer"]': { gridArea: 'footer' }
}

const SplitEven = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr) )',
  gridTemplateRows: 'auto',
  gridAutoFlow: 'column'
}

const page = {
  height: '100%',
  width: '100%',
  maxWidth: '100%',
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  gridTemplateColumns: 'auto 1fr auto',
  gridTemplateAreas: `
    "header header header"
    "sidebar-primary content sidebar-secondary"
    "footer footer footer"
  `,

  '& [slot="header"]': { gridArea: 'header' },
  '& [slot="content"]': { gridArea: 'content' },
  '& [slot="sidebar-primary"]': { gridArea: 'sidebar-primary' },
  '& [slot="sidebar-secondary"]': { gridArea: 'sidebar-secondary' },
  '& [slot="footer"]': { gridArea: 'footer' }
}

const sidebarLayout = {
  height: '100%',
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  gridTemplateColumns: 'auto  1fr auto ',
  gridTemplateAreas: `
    "sidebar-primary header header"
    "sidebar-primary content sidebar-secondary"
    "sidebar-primary footer footer"
  `,

  '& [slot="header"]': { gridArea: 'header' },
  '& [slot="content"]': { gridArea: 'content' },
  '& [slot="sidebar-primary"]': { gridArea: 'sidebar-primary' },
  '& [slot="sidebar-secondary"]': { gridArea: 'sidebar-secondary' },
  '& [slot="footer"]': { gridArea: 'footer' }
}

const layouts = {
  default: Default,
  row: Row,
  'row-wrap': RowWrap,
  page: page,
  sidebar: sidebarLayout,
  'off-center': OffCenter,
  'off-center-s': OffCenterSmall,
  'off-center-m': OffCenterMedium,
  'vertical-top': VerticalTop,
  'vertical-top-m': VerticalTopMedium,
  'split-even': SplitEven
}

const roundnessStyles = css({
  '[data-roundness="none"]': { borderRadius: 'var(--roundess-none, var(--space-s))' },
  '[data-roundness="s"]': { borderRadius: 'var(--roundess-s, var(--space-s))' },
  '[data-roundness="m"]': { borderRadius: 'var(--roundess-m, var(--space-m))' },
  '[data-roundness="l"]': { borderRadius: 'var(--roundess-l, var(--space-l))' },
  '[data-roundness="rounded"]': { borderRadius: 'var(--roundess-rounded, 99999px)' },
})

const insetStyles = css({
  '[data-inset="none"]': { padding: 'var(--space-none)' },
  '[data-inset="s"]': { padding: 'var(--space-s)' },
  '[data-inset="m"]': { padding: 'var(--space-m)' },
  '[data-inset="l m"]': { padding: 'var(--space-l) var(--space-m)' },
  '[data-inset="stretch-m"]': { padding: 'var(--space-s) var(--space-m)' },
  '[data-inset="l"]': { padding: 'var(--space-l)' },
  '[data-inset="xl"]': { padding: 'var(--space-xl)' },
  '[data-inset="xxl"]': { padding: 'var(--space-xxl)' },
})

export const View = ({
  surface = 'neutral',
  selectable,
  layout,
  placeItems = 'stretch stretch',
  placeContent = 'normal normal',
  alignItems,
  justifyContent,
  gap,
  roundness,
  inset,
  as: Container = 'div',
  ...props
}) => {

  const layoutStyles = useMemo(() => css({
    gap: gap ? `var(--space-${gap})` : undefined,
    placeItems: !alignItems ? placeItems : undefined,
    placeContent: !justifyContent ? placeContent : undefined,
    alignItems,
    justifyContent,
    minWidth: '0',
    minHeight: '0',
    maxWidth: '100%',
    // width: '100%',
    ...(layouts[layout] || Default)
  }), [layout])

  return <Container
    data-view
    data-surface={surface}
    data-selectable={selectable}
    data-roundness={roundness}
    data-layout={layout}
    data-inset={inset}
    {...props}
    {...layoutStyles}
    {...roundnessStyles}
    {...insetStyles}
  />
}
