import { useRef } from 'react';
import { useCarousel } from './useCarousel.jsx';
import { Icon } from '../icon';
import { View } from '../view';
import { css } from 'glamor';

const hideScrollbar = css({
 ' -ms-overflow-style': 'none',
  'scrollbar-width': 'none',
  '::-webkit-scrollbar': {
    display: 'none'
  }
})

export const Carousel = ({
  childWidth = '100%',
  childMaxWidth = '100%',
  children,
  ...props
}) => {
  const scrollContainerRef = useRef(null);

  const {
    children: childrenAsCarouselItemComponents,
    carouselItems,
    nextSlide,
    prevSlide,
  } = useCarousel(scrollContainerRef, children, { childWidth, childMaxWidth });

  return (
    <View {...props}>

      <View
        ref={scrollContainerRef}
        layout="row"
        {...hideScrollbar}
        style={{ overflowX: 'auto', overflowY: 'hidden', gap: 'var(--space-m)' }}
      >
        { childrenAsCarouselItemComponents }
      </View>



      <View
        layout="row"
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 'var(--space-s)',
          flexDirection: 'row',
          position: 'relative',
          padding: 'var(--space-m)'
        }}
      >
        <View layout="row" style={{ position: 'absolute', left: 'var(--space-s)', top: 'var(--space-s)', gap: 'var(--space-s)' }}>
          <Icon name="Previous" onClick={prevSlide} />
          <Icon name="Next" onClick={nextSlide} />
        </View>
        
        { carouselItems.map(item => <div
            key={item.itemId}
            style={{
              width: '8px',
              height: '8px',
              borderRadius: '50%',
              overflow: 'hidden',
              transition: 'background-color .3s ease',
              backgroundColor: item?.isVisible
                ? 'hsl(0, 0%, 40%)'
                : 'hsl(0, 0%, 90%)'
            }}

        />)}
      </View>

    </View>
  )
}
