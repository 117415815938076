import React from 'react'
import './Stack.css'
import { View } from '..'

export const Stack = ({
  horizontal = false,
  bordered,
  className = '',
  layout,
  ...props
}) => (
  <View
    layout={layout || horizontal ? 'row' : 'column' } 
    data-horizontal={horizontal}
    data-bordered={bordered}
    className="ossy-stack"
    {...props}
  />
)

export const StackItem = ({
  fill,
  className = '',
  ...props
}) => (
  <View
    layout='none'
    data-fill={fill}
    className={'ossy-stack__item'}
    {...props}
  />
)

Stack.Item = StackItem
