import { toHsl } from './toHsl'

export const createCss = (theme, options) => {
  if (!theme) return ''
  const { space, surfaces } = theme
  // console.log('[Styles] Creating css')
  // console.log('[Styles] Proccesing spacing', surfaces)

  const spacingCss = createCssVars(space)
  // console.log('[Styles] Proccesing vars', spacingCss)

  const surfacesCss = createSurfacesCss(surfaces)
  // console.log('[Styles] Proccesing vars', surfacesCss)

  return surfacesCss
}

// createCss(Standard)

// function createCssUtilClasses(themePortion) {
//   return getCssVars(themePortion)
//     .flatMap(Object.entries)
//     .reduce((acc, [name, value]) => {
//       return acc + `${name}: ${value};\n`
//     }, '')
// }

function createCssVars(themePortion) {
  if (!themePortion) return ''
  return getCssVars(themePortion)
    .flatMap(Object.entries)
    .reduce((acc, [name, value]) => {
      return acc + `${name}: ${value};\n`
    }, '')
}

function getCssVars(styleObject, cssVars = [], _previousPath = '') {
  return Object.entries(styleObject).map(([key, value]) => {

    if (typeof value === 'object') {
      return !!_previousPath
        ? getCssVars(value, cssVars, `${_previousPath}-${key}`)
        : getCssVars(value, cssVars, `--${key}`)
    } else if (!!_previousPath) {
      return { [`${_previousPath}-${key}`]: value }
    } else {
      return { [`--${key}`]: value }
    }
  }).flat()
}


export function differenciateColor(color) {
  const [h, s, l, a = 1] = toHsl(color)
  const isDark = l < 50
  const isTransparent = a < 1
  const isNeutral = s < 10
  
  const newl = isDark ? l + 5 : l - 5
  return `hsla(${h}, ${s}%, ${newl}%, ${a})`
}

function addSurfaceDefaults(surface) {
  const foreground = surface.foreground || 'hsl(182, 23%, 30%)'
  const foregroundHover = surface['foreground-hover'] || differenciateColor(foreground)
  const foregroundActive = surface['foreground-active'] || differenciateColor(foregroundHover)

  const background = surface.background || 'hsl(0, 0%, 99%)'
  const backgroundHover = surface['background-hover'] || differenciateColor(background)
  const backgroundActive = surface['background-active'] || differenciateColor(backgroundHover)

  return {
    foreground: foreground,
    'foreground-hover': foregroundHover,
    'foreground-active': foregroundActive,
    background: background,
    'background-hover': backgroundHover,
    'background-active': backgroundActive,
    backdropFilter: 'saturate(180%) blur(10px)',
    ...surface
  }
}



const defaultCss = `
[data-surface] {\n
  background: var(--background, trasparent);
  color: var(--foreground);
  fill: var(--foreground);
  backdrop-filter: var(--backdropFilter);
  transition: background .3s, color .3s, fill .3s;
}\n

[data-surface="neutral"] {\n
  --background: transparent;
  --backdropFilter: none;
}\n

[data-selectable] {\n
  cursor: pointer
}\n

[data-selectable]:hover {\n
  --background: var(--background-hover);
  --foreground: var(--foreground-hover);
}\n

[data-selectable]:active {\n
  --background: var(--background-active);
  --foreground: var(--foreground-active);
}\n
`

function createSurfacesCss(surfaces) {
  if (!surfaces) return ''
  return Object.entries(surfaces)
    .reduce((css, [surfaceName, rules]) => {
      // console.log('surfaceName', surfaceName)
      // console.log('rules', rules)
      const vars = createCssVars(addSurfaceDefaults(rules))
      return css + (
`\n
[data-surface="${surfaceName}"] {\n
  ${vars}
}\n
`
      )
    }, defaultCss)



}
