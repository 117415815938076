import React, { useMemo } from 'react'
import { css } from 'glamor'
import { View } from '../view/View.jsx'

export const Card = ({
  variant,
  ...props
}) => {

  const styles = useMemo(() => {
    return css({
      boxShadow: `var(--card-${variant}-box-shadow, var(--card-default-box-shadow))`,
      padding: `var(--card-${variant}-padding, var(--card-default-padding, var(--space-m)))`,
      borderRadius: `var(--card-${variant}-border-radius, var(--card-default-border-radius))`,
      background: `var(--card-${variant}-background, var(--card-default-background))`,
      backdropFilter: `var(--card-${variant}-backdrop-filter, var(--card-default-backdrop-filter))`,
      borderWidth: `var(--card-${variant}-border-width, var(--card-default-border-width))`,
      borderStyle: `var(--card-${variant}-border-style, var(--card-default-border-style))`,
      borderColor: `var(--card-${variant}-border-color, var(--card-default-border-color))`,
      overflow: 'hidden',
      boxSizing: 'border-box',
    }, [variant])
  })

  return <View {...props} {...styles} />
}
