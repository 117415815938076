import React, { useState, useEffect, forwardRef, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { css } from 'glamor'

const overlayRootId = 'ossy-overlay-root'

const defaultOverlayStyles = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  overflow: 'hidden',
  background: 'hsla(0, 0%, 0%, .8)',
  zIndex: '100',
}

const overlayContentStyles = {
  display: 'contents'
}

let fadeInAnimation = css.keyframes({ 
  '0%': { transform: 'scale(1)', opacity: 0 },
  '60%': { transform: 'scale(1)',},
  '100%': { opacity: 1 }
})

const fadeIn = css({
  animation: `${fadeInAnimation} ease .3s`,
  animationIterationCount: 1,
  animationFillMode: 'forwards',
})

export const Overlay = forwardRef(({
  children,
  isVisible = false,
  onClose = () => {},
  style: overriddenOverlayStyles = {}
}, ref) => {
  const [overlayRoot, setOverlayRoot] = useState()

  const overlayStyles = useMemo(() => ({
    ...defaultOverlayStyles,
    ...overriddenOverlayStyles
  }), [overriddenOverlayStyles])

  const onContentClick = event => {
    event.stopPropagation()
  }

  useEffect(() => {
    let overlayRoot = document.getElementById(overlayRootId)
    if (!!overlayRoot) return setOverlayRoot(overlayRoot)
    overlayRoot = document.createElement('div')
    overlayRoot.id = overlayRootId
    const body = document.querySelector('body')
    body.appendChild(overlayRoot)
    setOverlayRoot(overlayRoot)
  }, [])

  return (
    <>
      {
        isVisible
        && !!overlayRoot
        && createPortal(
          <div ref={ref} onClick={onClose} style={overlayStyles} {...fadeIn}>
            <div onClick={onContentClick} style={overlayContentStyles}>
              {children}
            </div>
          </div>,
          overlayRoot
        )
      }
    </>
  )
})
