import React from 'react'
import { css } from 'glamor'
import { Tabs } from '../../tabs/index.js'
import { Button } from '../../button'
import { Title } from '../../title'

const styles = css({
  height: '100%',
  margin: 0,

  boxSizing: 'border-box',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '16px',

  '& [data-slot="logo"]': {
    order: '1',
    display: 'flex',
    justifyContent: 'center',
  },

  '& [data-slot="navigation"]': {
    order: '2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },

  '& [data-slot="actions"]': {
    order: '3',
    display: 'flex',
    flexShrink: '0',
    flexGrow: '1',
    justifyContent: 'flex-end',
    gap: '8px'
  },

  '@media (min-width: 450px)': {

    '& [data-slot="logo"]': {
      justifyContent: 'flex-start',
    },

    '& [data-slot="actions"]': {
    },
  }



})

export const Header = ({
  logo,
  actions = [],
  navigation = [],
  navigationVariant = 'link'
}) => {

  return (
    <div {...styles}>

      <div data-slot="logo">
        { typeof logo !== 'string'
          ? logo
          : (
            <Title variant="logo">
              {logo}
            </Title>
        )}
      </div>

      <Tabs
        data-slot="navigation"
        variant={navigationVariant}
        tabs={navigation}
      />

      <div data-slot="actions">
        {actions.map(x => <Button {...x} /> )}
      </div>

    </div>
  )
}
