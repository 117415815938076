
'use client'
import { lazy, Suspense, useMemo } from 'react'
import { css } from 'glamor'

export const Icons = {
    'abstract': lazy(() => import('./icons2/abstract.jsx')), 
'add-r': lazy(() => import('./icons2/add-r.jsx')), 
'add': lazy(() => import('./icons2/add.jsx')), 
'adidas': lazy(() => import('./icons2/adidas.jsx')), 
'airplane': lazy(() => import('./icons2/airplane.jsx')), 
'alarm': lazy(() => import('./icons2/alarm.jsx')), 
'album': lazy(() => import('./icons2/album.jsx')), 
'align-bottom': lazy(() => import('./icons2/align-bottom.jsx')), 
'align-center': lazy(() => import('./icons2/align-center.jsx')), 
'align-left': lazy(() => import('./icons2/align-left.jsx')), 
'align-middle': lazy(() => import('./icons2/align-middle.jsx')), 
'align-right': lazy(() => import('./icons2/align-right.jsx')), 
'align-top': lazy(() => import('./icons2/align-top.jsx')), 
'anchor': lazy(() => import('./icons2/anchor.jsx')), 
'apple-watch': lazy(() => import('./icons2/apple-watch.jsx')), 
'arrange-back': lazy(() => import('./icons2/arrange-back.jsx')), 
'arrange-front': lazy(() => import('./icons2/arrange-front.jsx')), 
'arrow-align-h': lazy(() => import('./icons2/arrow-align-h.jsx')), 
'arrow-align-v': lazy(() => import('./icons2/arrow-align-v.jsx')), 
'arrow-bottom-left-o': lazy(() => import('./icons2/arrow-bottom-left-o.jsx')), 
'arrow-bottom-left-r': lazy(() => import('./icons2/arrow-bottom-left-r.jsx')), 
'arrow-bottom-left': lazy(() => import('./icons2/arrow-bottom-left.jsx')), 
'arrow-bottom-right-o': lazy(() => import('./icons2/arrow-bottom-right-o.jsx')), 
'arrow-bottom-right-r': lazy(() => import('./icons2/arrow-bottom-right-r.jsx')), 
'arrow-bottom-right': lazy(() => import('./icons2/arrow-bottom-right.jsx')), 
'arrow-down-o': lazy(() => import('./icons2/arrow-down-o.jsx')), 
'arrow-down-r': lazy(() => import('./icons2/arrow-down-r.jsx')), 
'arrow-down': lazy(() => import('./icons2/arrow-down.jsx')), 
'arrow-left-o': lazy(() => import('./icons2/arrow-left-o.jsx')), 
'arrow-left-r': lazy(() => import('./icons2/arrow-left-r.jsx')), 
'arrow-left': lazy(() => import('./icons2/arrow-left.jsx')), 
'arrow-long-down-c': lazy(() => import('./icons2/arrow-long-down-c.jsx')), 
'arrow-long-down-e': lazy(() => import('./icons2/arrow-long-down-e.jsx')), 
'arrow-long-down-l': lazy(() => import('./icons2/arrow-long-down-l.jsx')), 
'arrow-long-down-r': lazy(() => import('./icons2/arrow-long-down-r.jsx')), 
'arrow-long-down': lazy(() => import('./icons2/arrow-long-down.jsx')), 
'arrow-long-left-c': lazy(() => import('./icons2/arrow-long-left-c.jsx')), 
'arrow-long-left-e': lazy(() => import('./icons2/arrow-long-left-e.jsx')), 
'arrow-long-left-l': lazy(() => import('./icons2/arrow-long-left-l.jsx')), 
'arrow-long-left-r': lazy(() => import('./icons2/arrow-long-left-r.jsx')), 
'arrow-long-left': lazy(() => import('./icons2/arrow-long-left.jsx')), 
'arrow-long-right-c': lazy(() => import('./icons2/arrow-long-right-c.jsx')), 
'arrow-long-right-e': lazy(() => import('./icons2/arrow-long-right-e.jsx')), 
'arrow-long-right-l': lazy(() => import('./icons2/arrow-long-right-l.jsx')), 
'arrow-long-right-r': lazy(() => import('./icons2/arrow-long-right-r.jsx')), 
'arrow-long-right': lazy(() => import('./icons2/arrow-long-right.jsx')), 
'arrow-long-up-c': lazy(() => import('./icons2/arrow-long-up-c.jsx')), 
'arrow-long-up-e': lazy(() => import('./icons2/arrow-long-up-e.jsx')), 
'arrow-long-up-l': lazy(() => import('./icons2/arrow-long-up-l.jsx')), 
'arrow-long-up-r': lazy(() => import('./icons2/arrow-long-up-r.jsx')), 
'arrow-long-up': lazy(() => import('./icons2/arrow-long-up.jsx')), 
'arrow-right-o': lazy(() => import('./icons2/arrow-right-o.jsx')), 
'arrow-right-r': lazy(() => import('./icons2/arrow-right-r.jsx')), 
'arrow-right': lazy(() => import('./icons2/arrow-right.jsx')), 
'arrow-top-left-o': lazy(() => import('./icons2/arrow-top-left-o.jsx')), 
'arrow-top-left-r': lazy(() => import('./icons2/arrow-top-left-r.jsx')), 
'arrow-top-left': lazy(() => import('./icons2/arrow-top-left.jsx')), 
'arrow-top-right-o': lazy(() => import('./icons2/arrow-top-right-o.jsx')), 
'arrow-top-right-r': lazy(() => import('./icons2/arrow-top-right-r.jsx')), 
'arrow-top-right': lazy(() => import('./icons2/arrow-top-right.jsx')), 
'arrow-up-o': lazy(() => import('./icons2/arrow-up-o.jsx')), 
'arrow-up-r': lazy(() => import('./icons2/arrow-up-r.jsx')), 
'arrow-up': lazy(() => import('./icons2/arrow-up.jsx')), 
'arrows-breake-h': lazy(() => import('./icons2/arrows-breake-h.jsx')), 
'arrows-breake-v': lazy(() => import('./icons2/arrows-breake-v.jsx')), 
'arrows-exchange-alt-v': lazy(() => import('./icons2/arrows-exchange-alt-v.jsx')), 
'arrows-exchange-alt': lazy(() => import('./icons2/arrows-exchange-alt.jsx')), 
'arrows-exchange-v': lazy(() => import('./icons2/arrows-exchange-v.jsx')), 
'arrows-exchange': lazy(() => import('./icons2/arrows-exchange.jsx')), 
'arrows-expand-down-left': lazy(() => import('./icons2/arrows-expand-down-left.jsx')), 
'arrows-expand-down-right': lazy(() => import('./icons2/arrows-expand-down-right.jsx')), 
'arrows-expand-left-alt': lazy(() => import('./icons2/arrows-expand-left-alt.jsx')), 
'arrows-expand-left': lazy(() => import('./icons2/arrows-expand-left.jsx')), 
'arrows-expand-right-alt': lazy(() => import('./icons2/arrows-expand-right-alt.jsx')), 
'arrows-expand-right': lazy(() => import('./icons2/arrows-expand-right.jsx')), 
'arrows-expand-up-left': lazy(() => import('./icons2/arrows-expand-up-left.jsx')), 
'arrows-expand-up-right': lazy(() => import('./icons2/arrows-expand-up-right.jsx')), 
'arrows-h-alt': lazy(() => import('./icons2/arrows-h-alt.jsx')), 
'arrows-h': lazy(() => import('./icons2/arrows-h.jsx')), 
'arrows-merge-alt-h': lazy(() => import('./icons2/arrows-merge-alt-h.jsx')), 
'arrows-merge-alt-v': lazy(() => import('./icons2/arrows-merge-alt-v.jsx')), 
'arrows-scroll-h': lazy(() => import('./icons2/arrows-scroll-h.jsx')), 
'arrows-scroll-v': lazy(() => import('./icons2/arrows-scroll-v.jsx')), 
'arrows-shrink-h': lazy(() => import('./icons2/arrows-shrink-h.jsx')), 
'arrows-shrink-v': lazy(() => import('./icons2/arrows-shrink-v.jsx')), 
'arrows-v-alt': lazy(() => import('./icons2/arrows-v-alt.jsx')), 
'arrows-v': lazy(() => import('./icons2/arrows-v.jsx')), 
'assign': lazy(() => import('./icons2/assign.jsx')), 
'asterisk': lazy(() => import('./icons2/asterisk.jsx')), 
'atlasian': lazy(() => import('./icons2/atlasian.jsx')), 
'attachment': lazy(() => import('./icons2/attachment.jsx')), 
'attribution': lazy(() => import('./icons2/attribution.jsx')), 
'awards': lazy(() => import('./icons2/awards.jsx')), 
'backspace': lazy(() => import('./icons2/backspace.jsx')), 
'band-aid': lazy(() => import('./icons2/band-aid.jsx')), 
'battery-empty': lazy(() => import('./icons2/battery-empty.jsx')), 
'battery-full': lazy(() => import('./icons2/battery-full.jsx')), 
'battery': lazy(() => import('./icons2/battery.jsx')), 
'bee': lazy(() => import('./icons2/bee.jsx')), 
'bell': lazy(() => import('./icons2/bell.jsx')), 
'bitbucket': lazy(() => import('./icons2/bitbucket.jsx')), 
'block': lazy(() => import('./icons2/block.jsx')), 
'bmw': lazy(() => import('./icons2/bmw.jsx')), 
'board': lazy(() => import('./icons2/board.jsx')), 
'bolt': lazy(() => import('./icons2/bolt.jsx')), 
'bookmark': lazy(() => import('./icons2/bookmark.jsx')), 
'border-all': lazy(() => import('./icons2/border-all.jsx')), 
'border-bottom': lazy(() => import('./icons2/border-bottom.jsx')), 
'border-left': lazy(() => import('./icons2/border-left.jsx')), 
'border-right': lazy(() => import('./icons2/border-right.jsx')), 
'border-style-dashed': lazy(() => import('./icons2/border-style-dashed.jsx')), 
'border-style-dotted': lazy(() => import('./icons2/border-style-dotted.jsx')), 
'border-style-solid': lazy(() => import('./icons2/border-style-solid.jsx')), 
'border-top': lazy(() => import('./icons2/border-top.jsx')), 
'bot': lazy(() => import('./icons2/bot.jsx')), 
'bowl': lazy(() => import('./icons2/bowl.jsx')), 
'box': lazy(() => import('./icons2/box.jsx')), 
'boy': lazy(() => import('./icons2/boy.jsx')), 
'brackets': lazy(() => import('./icons2/brackets.jsx')), 
'briefcase': lazy(() => import('./icons2/briefcase.jsx')), 
'browse': lazy(() => import('./icons2/browse.jsx')), 
'browser': lazy(() => import('./icons2/browser.jsx')), 
'brush': lazy(() => import('./icons2/brush.jsx')), 
'bulb': lazy(() => import('./icons2/bulb.jsx')), 
'c-plus-plus': lazy(() => import('./icons2/c-plus-plus.jsx')), 
'calculator': lazy(() => import('./icons2/calculator.jsx')), 
'calendar-dates': lazy(() => import('./icons2/calendar-dates.jsx')), 
'calendar-due': lazy(() => import('./icons2/calendar-due.jsx')), 
'calendar-next': lazy(() => import('./icons2/calendar-next.jsx')), 
'calendar-today': lazy(() => import('./icons2/calendar-today.jsx')), 
'calendar-two': lazy(() => import('./icons2/calendar-two.jsx')), 
'calendar': lazy(() => import('./icons2/calendar.jsx')), 
'calibrate': lazy(() => import('./icons2/calibrate.jsx')), 
'camera': lazy(() => import('./icons2/camera.jsx')), 
'cap': lazy(() => import('./icons2/cap.jsx')), 
'captions': lazy(() => import('./icons2/captions.jsx')), 
'card-clubs': lazy(() => import('./icons2/card-clubs.jsx')), 
'card-diamonds': lazy(() => import('./icons2/card-diamonds.jsx')), 
'card-hearts': lazy(() => import('./icons2/card-hearts.jsx')), 
'card-spades': lazy(() => import('./icons2/card-spades.jsx')), 
'carousel': lazy(() => import('./icons2/carousel.jsx')), 
'cast': lazy(() => import('./icons2/cast.jsx')), 
'chanel': lazy(() => import('./icons2/chanel.jsx')), 
'chart': lazy(() => import('./icons2/chart.jsx')), 
'check-o': lazy(() => import('./icons2/check-o.jsx')), 
'check-r': lazy(() => import('./icons2/check-r.jsx')), 
'check': lazy(() => import('./icons2/check.jsx')), 
'chevron-double-down-o': lazy(() => import('./icons2/chevron-double-down-o.jsx')), 
'chevron-double-down-r': lazy(() => import('./icons2/chevron-double-down-r.jsx')), 
'chevron-double-down': lazy(() => import('./icons2/chevron-double-down.jsx')), 
'chevron-double-left-o': lazy(() => import('./icons2/chevron-double-left-o.jsx')), 
'chevron-double-left-r': lazy(() => import('./icons2/chevron-double-left-r.jsx')), 
'chevron-double-left': lazy(() => import('./icons2/chevron-double-left.jsx')), 
'chevron-double-right-o': lazy(() => import('./icons2/chevron-double-right-o.jsx')), 
'chevron-double-right-r': lazy(() => import('./icons2/chevron-double-right-r.jsx')), 
'chevron-double-right': lazy(() => import('./icons2/chevron-double-right.jsx')), 
'chevron-double-up-o': lazy(() => import('./icons2/chevron-double-up-o.jsx')), 
'chevron-double-up-r': lazy(() => import('./icons2/chevron-double-up-r.jsx')), 
'chevron-double-up': lazy(() => import('./icons2/chevron-double-up.jsx')), 
'chevron-down-o': lazy(() => import('./icons2/chevron-down-o.jsx')), 
'chevron-down-r': lazy(() => import('./icons2/chevron-down-r.jsx')), 
'chevron-down': lazy(() => import('./icons2/chevron-down.jsx')), 
'chevron-left-o': lazy(() => import('./icons2/chevron-left-o.jsx')), 
'chevron-left-r': lazy(() => import('./icons2/chevron-left-r.jsx')), 
'chevron-left': lazy(() => import('./icons2/chevron-left.jsx')), 
'chevron-right-o': lazy(() => import('./icons2/chevron-right-o.jsx')), 
'chevron-right-r': lazy(() => import('./icons2/chevron-right-r.jsx')), 
'chevron-right': lazy(() => import('./icons2/chevron-right.jsx')), 
'chevron-up-o': lazy(() => import('./icons2/chevron-up-o.jsx')), 
'chevron-up-r': lazy(() => import('./icons2/chevron-up-r.jsx')), 
'chevron-up': lazy(() => import('./icons2/chevron-up.jsx')), 
'circleci': lazy(() => import('./icons2/circleci.jsx')), 
'clapper-board': lazy(() => import('./icons2/clapper-board.jsx')), 
'clipboard': lazy(() => import('./icons2/clipboard.jsx')), 
'close-o': lazy(() => import('./icons2/close-o.jsx')), 
'close-r': lazy(() => import('./icons2/close-r.jsx')), 
'close': lazy(() => import('./icons2/close.jsx')), 
'cloud': lazy(() => import('./icons2/cloud.jsx')), 
'code-climate': lazy(() => import('./icons2/code-climate.jsx')), 
'code-slash': lazy(() => import('./icons2/code-slash.jsx')), 
'code': lazy(() => import('./icons2/code.jsx')), 
'coffee': lazy(() => import('./icons2/coffee.jsx')), 
'collage': lazy(() => import('./icons2/collage.jsx')), 
'color-bucket': lazy(() => import('./icons2/color-bucket.jsx')), 
'color-picker': lazy(() => import('./icons2/color-picker.jsx')), 
'comedy-central': lazy(() => import('./icons2/comedy-central.jsx')), 
'comment': lazy(() => import('./icons2/comment.jsx')), 
'community': lazy(() => import('./icons2/community.jsx')), 
'components': lazy(() => import('./icons2/components.jsx')), 
'compress-left': lazy(() => import('./icons2/compress-left.jsx')), 
'compress-right': lazy(() => import('./icons2/compress-right.jsx')), 
'compress-v': lazy(() => import('./icons2/compress-v.jsx')), 
'compress': lazy(() => import('./icons2/compress.jsx')), 
'controller': lazy(() => import('./icons2/controller.jsx')), 
'copy': lazy(() => import('./icons2/copy.jsx')), 
'copyright': lazy(() => import('./icons2/copyright.jsx')), 
'corner-double-down-left': lazy(() => import('./icons2/corner-double-down-left.jsx')), 
'corner-double-down-right': lazy(() => import('./icons2/corner-double-down-right.jsx')), 
'corner-double-left-down': lazy(() => import('./icons2/corner-double-left-down.jsx')), 
'corner-double-left-up': lazy(() => import('./icons2/corner-double-left-up.jsx')), 
'corner-double-right-down': lazy(() => import('./icons2/corner-double-right-down.jsx')), 
'corner-double-right-up': lazy(() => import('./icons2/corner-double-right-up.jsx')), 
'corner-double-up-left': lazy(() => import('./icons2/corner-double-up-left.jsx')), 
'corner-double-up-right': lazy(() => import('./icons2/corner-double-up-right.jsx')), 
'corner-down-left': lazy(() => import('./icons2/corner-down-left.jsx')), 
'corner-down-right': lazy(() => import('./icons2/corner-down-right.jsx')), 
'corner-left-down': lazy(() => import('./icons2/corner-left-down.jsx')), 
'corner-left-up': lazy(() => import('./icons2/corner-left-up.jsx')), 
'corner-right-down': lazy(() => import('./icons2/corner-right-down.jsx')), 
'corner-right-up': lazy(() => import('./icons2/corner-right-up.jsx')), 
'corner-up-left': lazy(() => import('./icons2/corner-up-left.jsx')), 
'corner-up-right': lazy(() => import('./icons2/corner-up-right.jsx')), 
'credit-card': lazy(() => import('./icons2/credit-card.jsx')), 
'crop': lazy(() => import('./icons2/crop.jsx')), 
'cross': lazy(() => import('./icons2/cross.jsx')), 
'crowdfire': lazy(() => import('./icons2/crowdfire.jsx')), 
'crown': lazy(() => import('./icons2/crown.jsx')), 
'danger': lazy(() => import('./icons2/danger.jsx')), 
'dark-mode': lazy(() => import('./icons2/dark-mode.jsx')), 
'data': lazy(() => import('./icons2/data.jsx')), 
'database': lazy(() => import('./icons2/database.jsx')), 
'debug': lazy(() => import('./icons2/debug.jsx')), 
'designmodo': lazy(() => import('./icons2/designmodo.jsx')), 
'desktop': lazy(() => import('./icons2/desktop.jsx')), 
'details-less': lazy(() => import('./icons2/details-less.jsx')), 
'details-more': lazy(() => import('./icons2/details-more.jsx')), 
'dialpad': lazy(() => import('./icons2/dialpad.jsx')), 
'dice-1': lazy(() => import('./icons2/dice-1.jsx')), 
'dice-2': lazy(() => import('./icons2/dice-2.jsx')), 
'dice-3': lazy(() => import('./icons2/dice-3.jsx')), 
'dice-4': lazy(() => import('./icons2/dice-4.jsx')), 
'dice-5': lazy(() => import('./icons2/dice-5.jsx')), 
'dice-6': lazy(() => import('./icons2/dice-6.jsx')), 
'digitalocean': lazy(() => import('./icons2/digitalocean.jsx')), 
'disc': lazy(() => import('./icons2/disc.jsx')), 
'display-flex': lazy(() => import('./icons2/display-flex.jsx')), 
'display-fullwidth': lazy(() => import('./icons2/display-fullwidth.jsx')), 
'display-grid': lazy(() => import('./icons2/display-grid.jsx')), 
'display-spacing': lazy(() => import('./icons2/display-spacing.jsx')), 
'distribute-horizontal': lazy(() => import('./icons2/distribute-horizontal.jsx')), 
'distribute-vertical': lazy(() => import('./icons2/distribute-vertical.jsx')), 
'dock-bottom': lazy(() => import('./icons2/dock-bottom.jsx')), 
'dock-left': lazy(() => import('./icons2/dock-left.jsx')), 
'dock-right': lazy(() => import('./icons2/dock-right.jsx')), 
'dock-window': lazy(() => import('./icons2/dock-window.jsx')), 
'dolby': lazy(() => import('./icons2/dolby.jsx')), 
'dollar': lazy(() => import('./icons2/dollar.jsx')), 
'dribbble': lazy(() => import('./icons2/dribbble.jsx')), 
'drive': lazy(() => import('./icons2/drive.jsx')), 
'drop-invert': lazy(() => import('./icons2/drop-invert.jsx')), 
'drop-opacity': lazy(() => import('./icons2/drop-opacity.jsx')), 
'drop': lazy(() => import('./icons2/drop.jsx')), 
'duplicate': lazy(() => import('./icons2/duplicate.jsx')), 
'edit-black-point': lazy(() => import('./icons2/edit-black-point.jsx')), 
'edit-contrast': lazy(() => import('./icons2/edit-contrast.jsx')), 
'edit-exposure': lazy(() => import('./icons2/edit-exposure.jsx')), 
'edit-fade': lazy(() => import('./icons2/edit-fade.jsx')), 
'edit-flip-h': lazy(() => import('./icons2/edit-flip-h.jsx')), 
'edit-flip-v': lazy(() => import('./icons2/edit-flip-v.jsx')), 
'edit-highlight': lazy(() => import('./icons2/edit-highlight.jsx')), 
'edit-markup': lazy(() => import('./icons2/edit-markup.jsx')), 
'edit-mask': lazy(() => import('./icons2/edit-mask.jsx')), 
'edit-noise': lazy(() => import('./icons2/edit-noise.jsx')), 
'edit-shadows': lazy(() => import('./icons2/edit-shadows.jsx')), 
'edit-straight': lazy(() => import('./icons2/edit-straight.jsx')), 
'edit-unmask': lazy(() => import('./icons2/edit-unmask.jsx')), 
'eject': lazy(() => import('./icons2/eject.jsx')), 
'enter': lazy(() => import('./icons2/enter.jsx')), 
'erase': lazy(() => import('./icons2/erase.jsx')), 
'ereader': lazy(() => import('./icons2/ereader.jsx')), 
'ericsson': lazy(() => import('./icons2/ericsson.jsx')), 
'ethernet': lazy(() => import('./icons2/ethernet.jsx')), 
'euro': lazy(() => import('./icons2/euro.jsx')), 
'eventbrite': lazy(() => import('./icons2/eventbrite.jsx')), 
'expand': lazy(() => import('./icons2/expand.jsx')), 
'export': lazy(() => import('./icons2/export.jsx')), 
'extension-add': lazy(() => import('./icons2/extension-add.jsx')), 
'extension-alt': lazy(() => import('./icons2/extension-alt.jsx')), 
'extension-remove': lazy(() => import('./icons2/extension-remove.jsx')), 
'extension': lazy(() => import('./icons2/extension.jsx')), 
'external': lazy(() => import('./icons2/external.jsx')), 
'eye-alt': lazy(() => import('./icons2/eye-alt.jsx')), 
'eye': lazy(() => import('./icons2/eye.jsx')), 
'facebook': lazy(() => import('./icons2/facebook.jsx')), 
'feed': lazy(() => import('./icons2/feed.jsx')), 
'figma': lazy(() => import('./icons2/figma.jsx')), 
'file-add': lazy(() => import('./icons2/file-add.jsx')), 
'file-document': lazy(() => import('./icons2/file-document.jsx')), 
'file-remove': lazy(() => import('./icons2/file-remove.jsx')), 
'file': lazy(() => import('./icons2/file.jsx')), 
'film': lazy(() => import('./icons2/film.jsx')), 
'filters': lazy(() => import('./icons2/filters.jsx')), 
'flag-alt': lazy(() => import('./icons2/flag-alt.jsx')), 
'flag': lazy(() => import('./icons2/flag.jsx')), 
'folder-add': lazy(() => import('./icons2/folder-add.jsx')), 
'folder-remove': lazy(() => import('./icons2/folder-remove.jsx')), 
'folder': lazy(() => import('./icons2/folder.jsx')), 
'font-height': lazy(() => import('./icons2/font-height.jsx')), 
'font-spacing': lazy(() => import('./icons2/font-spacing.jsx')), 
'format-bold': lazy(() => import('./icons2/format-bold.jsx')), 
'format-center': lazy(() => import('./icons2/format-center.jsx')), 
'format-color': lazy(() => import('./icons2/format-color.jsx')), 
'format-heading': lazy(() => import('./icons2/format-heading.jsx')), 
'format-indent-decrease': lazy(() => import('./icons2/format-indent-decrease.jsx')), 
'format-indent-increase': lazy(() => import('./icons2/format-indent-increase.jsx')), 
'format-italic': lazy(() => import('./icons2/format-italic.jsx')), 
'format-justify': lazy(() => import('./icons2/format-justify.jsx')), 
'format-left': lazy(() => import('./icons2/format-left.jsx')), 
'format-line-height': lazy(() => import('./icons2/format-line-height.jsx')), 
'format-right': lazy(() => import('./icons2/format-right.jsx')), 
'format-separator': lazy(() => import('./icons2/format-separator.jsx')), 
'format-slash': lazy(() => import('./icons2/format-slash.jsx')), 
'format-strike': lazy(() => import('./icons2/format-strike.jsx')), 
'format-text': lazy(() => import('./icons2/format-text.jsx')), 
'format-underline': lazy(() => import('./icons2/format-underline.jsx')), 
'format-uppercase': lazy(() => import('./icons2/format-uppercase.jsx')), 
'framer': lazy(() => import('./icons2/framer.jsx')), 
'games': lazy(() => import('./icons2/games.jsx')), 
'gender-female': lazy(() => import('./icons2/gender-female.jsx')), 
'gender-male': lazy(() => import('./icons2/gender-male.jsx')), 
'ghost-character': lazy(() => import('./icons2/ghost-character.jsx')), 
'ghost': lazy(() => import('./icons2/ghost.jsx')), 
'gift': lazy(() => import('./icons2/gift.jsx')), 
'girl': lazy(() => import('./icons2/girl.jsx')), 
'git-branch': lazy(() => import('./icons2/git-branch.jsx')), 
'git-commit': lazy(() => import('./icons2/git-commit.jsx')), 
'git-fork': lazy(() => import('./icons2/git-fork.jsx')), 
'git-pull': lazy(() => import('./icons2/git-pull.jsx')), 
'gitter': lazy(() => import('./icons2/gitter.jsx')), 
'glass-alt': lazy(() => import('./icons2/glass-alt.jsx')), 
'glass': lazy(() => import('./icons2/glass.jsx')), 
'globe-alt': lazy(() => import('./icons2/globe-alt.jsx')), 
'globe': lazy(() => import('./icons2/globe.jsx')), 
'google-tasks': lazy(() => import('./icons2/google-tasks.jsx')), 
'google': lazy(() => import('./icons2/google.jsx')), 
'gym': lazy(() => import('./icons2/gym.jsx')), 
'hashtag': lazy(() => import('./icons2/hashtag.jsx')), 
'headset': lazy(() => import('./icons2/headset.jsx')), 
'heart': lazy(() => import('./icons2/heart.jsx')), 
'hello': lazy(() => import('./icons2/hello.jsx')), 
'home-alt': lazy(() => import('./icons2/home-alt.jsx')), 
'home-screen': lazy(() => import('./icons2/home-screen.jsx')), 
'home': lazy(() => import('./icons2/home.jsx')), 
'icecream': lazy(() => import('./icons2/icecream.jsx')), 
'if-design': lazy(() => import('./icons2/if-design.jsx')), 
'image': lazy(() => import('./icons2/image.jsx')), 
'import': lazy(() => import('./icons2/import.jsx')), 
'inbox': lazy(() => import('./icons2/inbox.jsx')), 
'indie-hackers': lazy(() => import('./icons2/indie-hackers.jsx')), 
'infinity': lazy(() => import('./icons2/infinity.jsx')), 
'info': lazy(() => import('./icons2/info.jsx')), 
'inpicture': lazy(() => import('./icons2/inpicture.jsx')), 
'insert-after-o': lazy(() => import('./icons2/insert-after-o.jsx')), 
'insert-after-r': lazy(() => import('./icons2/insert-after-r.jsx')), 
'insert-after': lazy(() => import('./icons2/insert-after.jsx')), 
'insert-before-o': lazy(() => import('./icons2/insert-before-o.jsx')), 
'insert-before-r': lazy(() => import('./icons2/insert-before-r.jsx')), 
'insert-before': lazy(() => import('./icons2/insert-before.jsx')), 
'insights': lazy(() => import('./icons2/insights.jsx')), 
'instagram': lazy(() => import('./icons2/instagram.jsx')), 
'internal': lazy(() => import('./icons2/internal.jsx')), 
'key': lazy(() => import('./icons2/key.jsx')), 
'keyboard': lazy(() => import('./icons2/keyboard.jsx')), 
'keyhole': lazy(() => import('./icons2/keyhole.jsx')), 
'laptop': lazy(() => import('./icons2/laptop.jsx')), 
'lastpass': lazy(() => import('./icons2/lastpass.jsx')), 
'layout-grid-small': lazy(() => import('./icons2/layout-grid-small.jsx')), 
'layout-grid': lazy(() => import('./icons2/layout-grid.jsx')), 
'layout-list': lazy(() => import('./icons2/layout-list.jsx')), 
'layout-pin': lazy(() => import('./icons2/layout-pin.jsx')), 
'linear': lazy(() => import('./icons2/linear.jsx')), 
'link': lazy(() => import('./icons2/link.jsx')), 
'list-tree': lazy(() => import('./icons2/list-tree.jsx')), 
'list': lazy(() => import('./icons2/list.jsx')), 
'live-photo': lazy(() => import('./icons2/live-photo.jsx')), 
'loadbar-alt': lazy(() => import('./icons2/loadbar-alt.jsx')), 
'loadbar-doc': lazy(() => import('./icons2/loadbar-doc.jsx')), 
'loadbar-sound': lazy(() => import('./icons2/loadbar-sound.jsx')), 
'loadbar': lazy(() => import('./icons2/loadbar.jsx')), 
'lock-unlock': lazy(() => import('./icons2/lock-unlock.jsx')), 
'lock': lazy(() => import('./icons2/lock.jsx')), 
'log-in': lazy(() => import('./icons2/log-in.jsx')), 
'log-off': lazy(() => import('./icons2/log-off.jsx')), 
'log-out': lazy(() => import('./icons2/log-out.jsx')), 
'loupe': lazy(() => import('./icons2/loupe.jsx')), 
'magnet': lazy(() => import('./icons2/magnet.jsx')), 
'mail-forward': lazy(() => import('./icons2/mail-forward.jsx')), 
'mail-open': lazy(() => import('./icons2/mail-open.jsx')), 
'mail-reply': lazy(() => import('./icons2/mail-reply.jsx')), 
'mail': lazy(() => import('./icons2/mail.jsx')), 
'math-divide': lazy(() => import('./icons2/math-divide.jsx')), 
'math-equal': lazy(() => import('./icons2/math-equal.jsx')), 
'math-minus': lazy(() => import('./icons2/math-minus.jsx')), 
'math-percent': lazy(() => import('./icons2/math-percent.jsx')), 
'math-plus': lazy(() => import('./icons2/math-plus.jsx')), 
'maximize-alt': lazy(() => import('./icons2/maximize-alt.jsx')), 
'maximize': lazy(() => import('./icons2/maximize.jsx')), 
'maze': lazy(() => import('./icons2/maze.jsx')), 
'media-live': lazy(() => import('./icons2/media-live.jsx')), 
'media-podcast': lazy(() => import('./icons2/media-podcast.jsx')), 
'menu-boxed': lazy(() => import('./icons2/menu-boxed.jsx')), 
'menu-cake': lazy(() => import('./icons2/menu-cake.jsx')), 
'menu-cheese': lazy(() => import('./icons2/menu-cheese.jsx')), 
'menu-grid-o': lazy(() => import('./icons2/menu-grid-o.jsx')), 
'menu-grid-r': lazy(() => import('./icons2/menu-grid-r.jsx')), 
'menu-hotdog': lazy(() => import('./icons2/menu-hotdog.jsx')), 
'menu-left-alt': lazy(() => import('./icons2/menu-left-alt.jsx')), 
'menu-left': lazy(() => import('./icons2/menu-left.jsx')), 
'menu-motion': lazy(() => import('./icons2/menu-motion.jsx')), 
'menu-oreos': lazy(() => import('./icons2/menu-oreos.jsx')), 
'menu-right-alt': lazy(() => import('./icons2/menu-right-alt.jsx')), 
'menu-right': lazy(() => import('./icons2/menu-right.jsx')), 
'menu-round': lazy(() => import('./icons2/menu-round.jsx')), 
'menu': lazy(() => import('./icons2/menu.jsx')), 
'merge-horizontal': lazy(() => import('./icons2/merge-horizontal.jsx')), 
'merge-vertical': lazy(() => import('./icons2/merge-vertical.jsx')), 
'mic': lazy(() => import('./icons2/mic.jsx')), 
'microbit': lazy(() => import('./icons2/microbit.jsx')), 
'microsoft': lazy(() => import('./icons2/microsoft.jsx')), 
'mini-player': lazy(() => import('./icons2/mini-player.jsx')), 
'minimize-alt': lazy(() => import('./icons2/minimize-alt.jsx')), 
'minimize': lazy(() => import('./icons2/minimize.jsx')), 
'modem': lazy(() => import('./icons2/modem.jsx')), 
'monday': lazy(() => import('./icons2/monday.jsx')), 
'moon': lazy(() => import('./icons2/moon.jsx')), 
'more-alt': lazy(() => import('./icons2/more-alt.jsx')), 
'more-o': lazy(() => import('./icons2/more-o.jsx')), 
'more-r': lazy(() => import('./icons2/more-r.jsx')), 
'more-vertical-alt': lazy(() => import('./icons2/more-vertical-alt.jsx')), 
'more-vertical-o': lazy(() => import('./icons2/more-vertical-o.jsx')), 
'more-vertical-r': lazy(() => import('./icons2/more-vertical-r.jsx')), 
'more-vertical': lazy(() => import('./icons2/more-vertical.jsx')), 
'more': lazy(() => import('./icons2/more.jsx')), 
'mouse': lazy(() => import('./icons2/mouse.jsx')), 
'move-down': lazy(() => import('./icons2/move-down.jsx')), 
'move-left': lazy(() => import('./icons2/move-left.jsx')), 
'move-right': lazy(() => import('./icons2/move-right.jsx')), 
'move-task': lazy(() => import('./icons2/move-task.jsx')), 
'move-up': lazy(() => import('./icons2/move-up.jsx')), 
'music-note': lazy(() => import('./icons2/music-note.jsx')), 
'music-speaker': lazy(() => import('./icons2/music-speaker.jsx')), 
'music': lazy(() => import('./icons2/music.jsx')), 
'nametag': lazy(() => import('./icons2/nametag.jsx')), 
'notes': lazy(() => import('./icons2/notes.jsx')), 
'notifications': lazy(() => import('./icons2/notifications.jsx')), 
'npm': lazy(() => import('./icons2/npm.jsx')), 
'oculus': lazy(() => import('./icons2/oculus.jsx')), 
'open-collective': lazy(() => import('./icons2/open-collective.jsx')), 
'options': lazy(() => import('./icons2/options.jsx')), 
'organisation': lazy(() => import('./icons2/organisation.jsx')), 
'overflow': lazy(() => import('./icons2/overflow.jsx')), 
'pacman': lazy(() => import('./icons2/pacman.jsx')), 
'password': lazy(() => import('./icons2/password.jsx')), 
'path-back': lazy(() => import('./icons2/path-back.jsx')), 
'path-crop': lazy(() => import('./icons2/path-crop.jsx')), 
'path-divide': lazy(() => import('./icons2/path-divide.jsx')), 
'path-exclude': lazy(() => import('./icons2/path-exclude.jsx')), 
'path-front': lazy(() => import('./icons2/path-front.jsx')), 
'path-intersect': lazy(() => import('./icons2/path-intersect.jsx')), 
'path-outline': lazy(() => import('./icons2/path-outline.jsx')), 
'path-trim': lazy(() => import('./icons2/path-trim.jsx')), 
'path-unite': lazy(() => import('./icons2/path-unite.jsx')), 
'patreon': lazy(() => import('./icons2/patreon.jsx')), 
'paypal': lazy(() => import('./icons2/paypal.jsx')), 
'pen': lazy(() => import('./icons2/pen.jsx')), 
'pentagon-bottom-left': lazy(() => import('./icons2/pentagon-bottom-left.jsx')), 
'pentagon-bottom-right': lazy(() => import('./icons2/pentagon-bottom-right.jsx')), 
'pentagon-down': lazy(() => import('./icons2/pentagon-down.jsx')), 
'pentagon-left': lazy(() => import('./icons2/pentagon-left.jsx')), 
'pentagon-right': lazy(() => import('./icons2/pentagon-right.jsx')), 
'pentagon-top-left': lazy(() => import('./icons2/pentagon-top-left.jsx')), 
'pentagon-top-right': lazy(() => import('./icons2/pentagon-top-right.jsx')), 
'pentagon-up': lazy(() => import('./icons2/pentagon-up.jsx')), 
'performance': lazy(() => import('./icons2/performance.jsx')), 
'pexels': lazy(() => import('./icons2/pexels.jsx')), 
'phone': lazy(() => import('./icons2/phone.jsx')), 
'photoscan': lazy(() => import('./icons2/photoscan.jsx')), 
'piano': lazy(() => import('./icons2/piano.jsx')), 
'pill': lazy(() => import('./icons2/pill.jsx')), 
'pin-alt': lazy(() => import('./icons2/pin-alt.jsx')), 
'pin-bottom': lazy(() => import('./icons2/pin-bottom.jsx')), 
'pin-top': lazy(() => import('./icons2/pin-top.jsx')), 
'pin': lazy(() => import('./icons2/pin.jsx')), 
'play-backwards': lazy(() => import('./icons2/play-backwards.jsx')), 
'play-button-o': lazy(() => import('./icons2/play-button-o.jsx')), 
'play-button-r': lazy(() => import('./icons2/play-button-r.jsx')), 
'play-button': lazy(() => import('./icons2/play-button.jsx')), 
'play-forwards': lazy(() => import('./icons2/play-forwards.jsx')), 
'play-list-add': lazy(() => import('./icons2/play-list-add.jsx')), 
'play-list-check': lazy(() => import('./icons2/play-list-check.jsx')), 
'play-list-remove': lazy(() => import('./icons2/play-list-remove.jsx')), 
'play-list-search': lazy(() => import('./icons2/play-list-search.jsx')), 
'play-list': lazy(() => import('./icons2/play-list.jsx')), 
'play-pause-o': lazy(() => import('./icons2/play-pause-o.jsx')), 
'play-pause-r': lazy(() => import('./icons2/play-pause-r.jsx')), 
'play-pause': lazy(() => import('./icons2/play-pause.jsx')), 
'play-stop-o': lazy(() => import('./icons2/play-stop-o.jsx')), 
'play-stop-r': lazy(() => import('./icons2/play-stop-r.jsx')), 
'play-stop': lazy(() => import('./icons2/play-stop.jsx')), 
'play-track-next-o': lazy(() => import('./icons2/play-track-next-o.jsx')), 
'play-track-next-r': lazy(() => import('./icons2/play-track-next-r.jsx')), 
'play-track-next': lazy(() => import('./icons2/play-track-next.jsx')), 
'play-track-prev-o': lazy(() => import('./icons2/play-track-prev-o.jsx')), 
'play-track-prev-r': lazy(() => import('./icons2/play-track-prev-r.jsx')), 
'play-track-prev': lazy(() => import('./icons2/play-track-prev.jsx')), 
'plug': lazy(() => import('./icons2/plug.jsx')), 
'pocket': lazy(() => import('./icons2/pocket.jsx')), 
'pokemon': lazy(() => import('./icons2/pokemon.jsx')), 
'polaroid': lazy(() => import('./icons2/polaroid.jsx')), 
'poll': lazy(() => import('./icons2/poll.jsx')), 
'presentation': lazy(() => import('./icons2/presentation.jsx')), 
'printer': lazy(() => import('./icons2/printer.jsx')), 
'product-hunt': lazy(() => import('./icons2/product-hunt.jsx')), 
'profile': lazy(() => import('./icons2/profile.jsx')), 
'pull-clear': lazy(() => import('./icons2/pull-clear.jsx')), 
'push-chevron-down-o': lazy(() => import('./icons2/push-chevron-down-o.jsx')), 
'push-chevron-down-r': lazy(() => import('./icons2/push-chevron-down-r.jsx')), 
'push-chevron-down': lazy(() => import('./icons2/push-chevron-down.jsx')), 
'push-chevron-left-o': lazy(() => import('./icons2/push-chevron-left-o.jsx')), 
'push-chevron-left-r': lazy(() => import('./icons2/push-chevron-left-r.jsx')), 
'push-chevron-left': lazy(() => import('./icons2/push-chevron-left.jsx')), 
'push-chevron-right-o': lazy(() => import('./icons2/push-chevron-right-o.jsx')), 
'push-chevron-right-r': lazy(() => import('./icons2/push-chevron-right-r.jsx')), 
'push-chevron-right': lazy(() => import('./icons2/push-chevron-right.jsx')), 
'push-chevron-up-o': lazy(() => import('./icons2/push-chevron-up-o.jsx')), 
'push-chevron-up-r': lazy(() => import('./icons2/push-chevron-up-r.jsx')), 
'push-chevron-up': lazy(() => import('./icons2/push-chevron-up.jsx')), 
'push-down': lazy(() => import('./icons2/push-down.jsx')), 
'push-left': lazy(() => import('./icons2/push-left.jsx')), 
'push-right': lazy(() => import('./icons2/push-right.jsx')), 
'push-up': lazy(() => import('./icons2/push-up.jsx')), 
'qr': lazy(() => import('./icons2/qr.jsx')), 
'quote-o': lazy(() => import('./icons2/quote-o.jsx')), 
'quote': lazy(() => import('./icons2/quote.jsx')), 
'radio-check': lazy(() => import('./icons2/radio-check.jsx')), 
'radio-checked': lazy(() => import('./icons2/radio-checked.jsx')), 
'ratio': lazy(() => import('./icons2/ratio.jsx')), 
'read': lazy(() => import('./icons2/read.jsx')), 
'readme': lazy(() => import('./icons2/readme.jsx')), 
'record': lazy(() => import('./icons2/record.jsx')), 
'redo': lazy(() => import('./icons2/redo.jsx')), 
'remote': lazy(() => import('./icons2/remote.jsx')), 
'remove-r': lazy(() => import('./icons2/remove-r.jsx')), 
'remove': lazy(() => import('./icons2/remove.jsx')), 
'rename': lazy(() => import('./icons2/rename.jsx')), 
'reorder': lazy(() => import('./icons2/reorder.jsx')), 
'repeat': lazy(() => import('./icons2/repeat.jsx')), 
'ring': lazy(() => import('./icons2/ring.jsx')), 
'row-first': lazy(() => import('./icons2/row-first.jsx')), 
'row-last': lazy(() => import('./icons2/row-last.jsx')), 
'ruler': lazy(() => import('./icons2/ruler.jsx')), 
'sand-clock': lazy(() => import('./icons2/sand-clock.jsx')), 
'scan': lazy(() => import('./icons2/scan.jsx')), 
'screen-mirror': lazy(() => import('./icons2/screen-mirror.jsx')), 
'screen-shot': lazy(() => import('./icons2/screen-shot.jsx')), 
'screen-wide': lazy(() => import('./icons2/screen-wide.jsx')), 
'screen': lazy(() => import('./icons2/screen.jsx')), 
'scroll-h': lazy(() => import('./icons2/scroll-h.jsx')), 
'scroll-v': lazy(() => import('./icons2/scroll-v.jsx')), 
'search-found': lazy(() => import('./icons2/search-found.jsx')), 
'search-loading': lazy(() => import('./icons2/search-loading.jsx')), 
'search': lazy(() => import('./icons2/search.jsx')), 
'select-o': lazy(() => import('./icons2/select-o.jsx')), 
'select-r': lazy(() => import('./icons2/select-r.jsx')), 
'select': lazy(() => import('./icons2/select.jsx')), 
'server': lazy(() => import('./icons2/server.jsx')), 
'serverless': lazy(() => import('./icons2/serverless.jsx')), 
'shape-circle': lazy(() => import('./icons2/shape-circle.jsx')), 
'shape-half-circle': lazy(() => import('./icons2/shape-half-circle.jsx')), 
'shape-hexagon': lazy(() => import('./icons2/shape-hexagon.jsx')), 
'shape-rhombus': lazy(() => import('./icons2/shape-rhombus.jsx')), 
'shape-square': lazy(() => import('./icons2/shape-square.jsx')), 
'shape-triangle': lazy(() => import('./icons2/shape-triangle.jsx')), 
'shape-zigzag': lazy(() => import('./icons2/shape-zigzag.jsx')), 
'share': lazy(() => import('./icons2/share.jsx')), 
'shield': lazy(() => import('./icons2/shield.jsx')), 
'shopping-bag': lazy(() => import('./icons2/shopping-bag.jsx')), 
'shopping-cart': lazy(() => import('./icons2/shopping-cart.jsx')), 
'shortcut': lazy(() => import('./icons2/shortcut.jsx')), 
'shutterstock': lazy(() => import('./icons2/shutterstock.jsx')), 
'sidebar-open': lazy(() => import('./icons2/sidebar-open.jsx')), 
'sidebar-right': lazy(() => import('./icons2/sidebar-right.jsx')), 
'sidebar': lazy(() => import('./icons2/sidebar.jsx')), 
'signal': lazy(() => import('./icons2/signal.jsx')), 
'size': lazy(() => import('./icons2/size.jsx')), 
'sketch': lazy(() => import('./icons2/sketch.jsx')), 
'slack': lazy(() => import('./icons2/slack.jsx')), 
'sleep': lazy(() => import('./icons2/sleep.jsx')), 
'smart-home-boiler': lazy(() => import('./icons2/smart-home-boiler.jsx')), 
'smart-home-cooker': lazy(() => import('./icons2/smart-home-cooker.jsx')), 
'smart-home-heat': lazy(() => import('./icons2/smart-home-heat.jsx')), 
'smart-home-light': lazy(() => import('./icons2/smart-home-light.jsx')), 
'smart-home-refrigerator': lazy(() => import('./icons2/smart-home-refrigerator.jsx')), 
'smart-home-wash-machine': lazy(() => import('./icons2/smart-home-wash-machine.jsx')), 
'smartphone-chip': lazy(() => import('./icons2/smartphone-chip.jsx')), 
'smartphone-ram': lazy(() => import('./icons2/smartphone-ram.jsx')), 
'smartphone-shake': lazy(() => import('./icons2/smartphone-shake.jsx')), 
'smartphone': lazy(() => import('./icons2/smartphone.jsx')), 
'smile-mouth-open': lazy(() => import('./icons2/smile-mouth-open.jsx')), 
'smile-neutral': lazy(() => import('./icons2/smile-neutral.jsx')), 
'smile-no-mouth': lazy(() => import('./icons2/smile-no-mouth.jsx')), 
'smile-none': lazy(() => import('./icons2/smile-none.jsx')), 
'smile-sad': lazy(() => import('./icons2/smile-sad.jsx')), 
'smile-upside': lazy(() => import('./icons2/smile-upside.jsx')), 
'smile': lazy(() => import('./icons2/smile.jsx')), 
'software-download': lazy(() => import('./icons2/software-download.jsx')), 
'software-upload': lazy(() => import('./icons2/software-upload.jsx')), 
'sort-az': lazy(() => import('./icons2/sort-az.jsx')), 
'sort-za': lazy(() => import('./icons2/sort-za.jsx')), 
'space-between-v': lazy(() => import('./icons2/space-between-v.jsx')), 
'space-between': lazy(() => import('./icons2/space-between.jsx')), 
'spectrum': lazy(() => import('./icons2/spectrum.jsx')), 
'spinner-alt': lazy(() => import('./icons2/spinner-alt.jsx')), 
'spinner-two-alt': lazy(() => import('./icons2/spinner-two-alt.jsx')), 
'spinner-two': lazy(() => import('./icons2/spinner-two.jsx')), 
'spinner': lazy(() => import('./icons2/spinner.jsx')), 
'square': lazy(() => import('./icons2/square.jsx')), 
'stack': lazy(() => import('./icons2/stack.jsx')), 
'stark': lazy(() => import('./icons2/stark.jsx')), 
'stopwatch': lazy(() => import('./icons2/stopwatch.jsx')), 
'stories': lazy(() => import('./icons2/stories.jsx')), 
'studio': lazy(() => import('./icons2/studio.jsx')), 
'style': lazy(() => import('./icons2/style.jsx')), 
'sun': lazy(() => import('./icons2/sun.jsx')), 
'support': lazy(() => import('./icons2/support.jsx')), 
'swap-vertical': lazy(() => import('./icons2/swap-vertical.jsx')), 
'swap': lazy(() => import('./icons2/swap.jsx')), 
'sweden': lazy(() => import('./icons2/sweden.jsx')), 
'swiss': lazy(() => import('./icons2/swiss.jsx')), 
'sync': lazy(() => import('./icons2/sync.jsx')), 
'tab': lazy(() => import('./icons2/tab.jsx')), 
'tag': lazy(() => import('./icons2/tag.jsx')), 
'tally': lazy(() => import('./icons2/tally.jsx')), 
'tap-double': lazy(() => import('./icons2/tap-double.jsx')), 
'tap-single': lazy(() => import('./icons2/tap-single.jsx')), 
'template': lazy(() => import('./icons2/template.jsx')), 
'tennis': lazy(() => import('./icons2/tennis.jsx')), 
'terminal': lazy(() => import('./icons2/terminal.jsx')), 
'terrain': lazy(() => import('./icons2/terrain.jsx')), 
'thermometer': lazy(() => import('./icons2/thermometer.jsx')), 
'thermostat': lazy(() => import('./icons2/thermostat.jsx')), 
'tikcode': lazy(() => import('./icons2/tikcode.jsx')), 
'time': lazy(() => import('./icons2/time.jsx')), 
'timelapse': lazy(() => import('./icons2/timelapse.jsx')), 
'timer': lazy(() => import('./icons2/timer.jsx')), 
'today': lazy(() => import('./icons2/today.jsx')), 
'toggle-off': lazy(() => import('./icons2/toggle-off.jsx')), 
'toggle-on': lazy(() => import('./icons2/toggle-on.jsx')), 
'toggle-square-off': lazy(() => import('./icons2/toggle-square-off.jsx')), 
'toggle-square': lazy(() => import('./icons2/toggle-square.jsx')), 
'toolbar-bottom': lazy(() => import('./icons2/toolbar-bottom.jsx')), 
'toolbar-left': lazy(() => import('./icons2/toolbar-left.jsx')), 
'toolbar-right': lazy(() => import('./icons2/toolbar-right.jsx')), 
'toolbar-top': lazy(() => import('./icons2/toolbar-top.jsx')), 
'toolbox': lazy(() => import('./icons2/toolbox.jsx')), 
'touchpad': lazy(() => import('./icons2/touchpad.jsx')), 
'track': lazy(() => import('./icons2/track.jsx')), 
'transcript': lazy(() => import('./icons2/transcript.jsx')), 
'trash-empty': lazy(() => import('./icons2/trash-empty.jsx')), 
'trash': lazy(() => import('./icons2/trash.jsx')), 
'tree': lazy(() => import('./icons2/tree.jsx')), 
'trees': lazy(() => import('./icons2/trees.jsx')), 
'trello': lazy(() => import('./icons2/trello.jsx')), 
'trending-down': lazy(() => import('./icons2/trending-down.jsx')), 
'trending': lazy(() => import('./icons2/trending.jsx')), 
'trophy': lazy(() => import('./icons2/trophy.jsx')), 
'tv': lazy(() => import('./icons2/tv.jsx')), 
'twilio': lazy(() => import('./icons2/twilio.jsx')), 
'twitter': lazy(() => import('./icons2/twitter.jsx')), 
'ui-kit': lazy(() => import('./icons2/ui-kit.jsx')), 
'umbrella': lazy(() => import('./icons2/umbrella.jsx')), 
'unavailable': lazy(() => import('./icons2/unavailable.jsx')), 
'unblock': lazy(() => import('./icons2/unblock.jsx')), 
'undo': lazy(() => import('./icons2/undo.jsx')), 
'unfold': lazy(() => import('./icons2/unfold.jsx')), 
'unsplash': lazy(() => import('./icons2/unsplash.jsx')), 
'usb-c': lazy(() => import('./icons2/usb-c.jsx')), 
'usb': lazy(() => import('./icons2/usb.jsx')), 
'user-add': lazy(() => import('./icons2/user-add.jsx')), 
'user-list': lazy(() => import('./icons2/user-list.jsx')), 
'user-remove': lazy(() => import('./icons2/user-remove.jsx')), 
'user': lazy(() => import('./icons2/user.jsx')), 
'userlane': lazy(() => import('./icons2/userlane.jsx')), 
'vercel': lazy(() => import('./icons2/vercel.jsx')), 
'view-cols': lazy(() => import('./icons2/view-cols.jsx')), 
'view-comfortable': lazy(() => import('./icons2/view-comfortable.jsx')), 
'view-day': lazy(() => import('./icons2/view-day.jsx')), 
'view-grid': lazy(() => import('./icons2/view-grid.jsx')), 
'view-list': lazy(() => import('./icons2/view-list.jsx')), 
'view-month': lazy(() => import('./icons2/view-month.jsx')), 
'view-split': lazy(() => import('./icons2/view-split.jsx')), 
'vinyl': lazy(() => import('./icons2/vinyl.jsx')), 
'voicemail-o': lazy(() => import('./icons2/voicemail-o.jsx')), 
'voicemail-r': lazy(() => import('./icons2/voicemail-r.jsx')), 
'voicemail': lazy(() => import('./icons2/voicemail.jsx')), 
'volume': lazy(() => import('./icons2/volume.jsx')), 
'webcam': lazy(() => import('./icons2/webcam.jsx')), 
'website': lazy(() => import('./icons2/website.jsx')), 
'windows': lazy(() => import('./icons2/windows.jsx')), 
'work-alt': lazy(() => import('./icons2/work-alt.jsx')), 
'yinyang': lazy(() => import('./icons2/yinyang.jsx')), 
'youtube': lazy(() => import('./icons2/youtube.jsx')), 
'zoom-in': lazy(() => import('./icons2/zoom-in.jsx')), 
'zoom-out': lazy(() => import('./icons2/zoom-out.jsx')), 

}

const iconSizes = css({
    '[data-size="s"]': {
      width: 'var(--icon-size-s, 18px)',
      height: 'var(--icon-size-s, 18px)',
    },
    '[data-size="m"]': {
      width: 'var(--icon-size-m, 24px)',
      height: 'var(--icon-size-m, 24px)',
    },
    '[data-size="l"]': {
      width: 'var(--icon-size-l, 48px)',
      height: 'var(--icon-size-l, 48px)',
    },
    '[data-size="xl"]': {
      width: 'var(--icon-size-l, 64px)',
      height: 'var(--icon-size-l, 64px)',
    },
  })

export const Icon2 = ({
  icon,
  name: _name,
  variant,
  size = 's',
  ...props
}) => {
    const name = icon || _name

    if (!name) {
        console.warn('[Icon] No name provided')
        return <></>
    }

    const Icon = Icons[name]

    if (!Icon) {
        console.warn('[Icon] No icon found with the name of: ', name)
        return <></>
    }

    const styles = useMemo(() => {
        return css({
          color: `var(--on-icon-${variant}, var(--on-icon-default))`,
          fill: `var(--on-icon-${variant}, var(--on-icon-default))`,
          height: `var(--icon-${variant}--size, var(--icon-default--size))`,
          width: `var(--icon-${variant}--size, var(--icon-default--size))`,
          fontSize: `var(--icon-${variant}--size, var(--icon-default--size))`,
          ':hover': {
            color: `var(--on-icon-${variant}--hover, var(--on-icon-default--hover))`,
            fill: `var(--on-icon-${variant}--hover, var(--on-icon-default--hover))`,
            cursor: `var(--icon-${variant}--cursor, var(--on-icon-default--cursor))`,
          },
          ':focus': {
            color: `var(--on-icon-${variant}--hover, var(--on-icon-default--hover))`,
            fill: `var(--on-icon-${variant}--hover, var(--on-icon-default--hover))`,
          },
          ':disabled': {
            color: `var(--on-icon-${variant}--disabled, var(--on-icon-default--disabled))`,
            fill: `var(--on-icon-${variant}--disabled, var(--on-icon-default--disabled))`,
            cursor: 'not-allowed'
          },
        })
      }, [variant])

    return (
        <Suspense fallback={<span {...iconSizes} data-size={size} />}>
            <Icon {...props} data-size={size} {...styles} {...iconSizes} />
        </Suspense>
    )
}
