import React from 'react'
import { css } from 'glamor'
import { View } from '../view'
import { Text } from '../text'
import { Button } from '../button'

const inputStyles = css({
  padding: '8px 16px',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'hsla(0, 0%, 75%, .1)',
  borderRadius: '5px',
  fontSize: '16px',
  boxShadow: '0 3px 6px hsla(0, 0%, 75%, 1)',
  '[disabled]': {
    cursor: 'not-allowed',
    color: 'hsl(0, 0%, 85%)',
    backgroundColor: 'hsl(0, 0%, 85%)'
  }
})

const input2Styles = css({
  padding: '8px 16px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'hsl(0, 0%, 90%)',
  borderRadius: '99999px',
  fontSize: '16px',
  lineHeight: '1',
  ':focus': {
    outline: 'none',
    boxShadow: '0 0 0 2px hsl(199, 98%, 43%)',
  }
})

const textarea2Styles = css({
  height: '120px',
  width: '100%',
  fontFamily: 'sans-serif',
  padding: '8px 16px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'hsl(0, 0%, 90%)',
  borderRadius: '16px',
  fontSize: '16px',
  lineHeight: '1',
  ':focus': {
    outline: 'none',
    boxShadow: '0 0 0 2px hsl(199, 98%, 43%)',
  }
})

const inputTitleStyle = css({
  fontFamily: 'sans-serif',
  lineHeight: 1,
  fontSize: '16px',
  border: 'none',
  backgroundColor: 'transparent'
})

export const Input = props =>
  <input {...inputStyles} {...props}/>

export const InputDisabled = props =>
  <input disabled {...inputStyles} {...props} />

export const InputTitle = props =>
  <input {...inputTitleStyle} {...props}/>

export const Input2 = props =>
  <input {...input2Styles} {...props}/>

export const Textarea = props =>
  <textarea {...textarea2Styles} {...props}/>

export const Select = props =>
  <select {...input2Styles} {...props}/>

const displayNone = css({
  display: 'none'
})

export const Upload = ({
  icon = 'math-plus',
  text = 'Upload',
  surface = 'primary',
  style = {},
  ...props
}) => {
  const id = 'ossy-upload-input'

  return (
    <>
      <View
        as="label"
        htmlFor={id}
        selectable
        surface={surface}
        inset="l"
        roundness="m"
        gap="s"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          ...style
        }}
      >
        <Button prefix="math-plus" variant="cta" style={{ padding: 'var(--space-m)' }} />
        <Text>{text}</Text>
      </View>
      <input id={id} type="file" {...displayNone} {...props}/>
    </>
  )
}
